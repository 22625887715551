import React, { FunctionComponent, useEffect, useState } from 'react';
import Masonry from 'react-masonry-css';
import Layout from '../components/layout/layout';
import MultiLangTags from '../components/multi-lang-tags/multi-lang-tags';
import MusicPostItem from '../components/music-post-item/music-post-item';
import MusicTagSelection from '../components/music-tag-selection/music-tag-selection';
import OpenGraphTags from '../components/open-graph-tags/open-graph-tags';
import SeoMetaTags from '../components/seo-meta-tags/seo-meta-tags';
import siteCover from '../images/site-cover.jpg';
import { Locale } from '../models/locale';
import { IMusicVideoEntry } from '../models/music-video-entry';
import { fetchMusicVideos } from '../services/cms';
import { buildCanonicalUrl } from '../utils/define';
import { useTranslation } from '../utils/i18n';
import { getQueryValue } from '../utils/parser';
import styles from './music.module.scss';

interface IProps {
  pageContext: {
    musicPosts: IMusicVideoEntry[];
    allTags: Array<{ tag: string; lang: Locale }>;
    intl: { language: Locale };
  };
}

const MusicPage: FunctionComponent<IProps> = ({ pageContext }) => {
  const { t } = useTranslation();
  const [items, setItems] = useState<IProps['pageContext']['musicPosts']>([
    ...pageContext.musicPosts.filter((p) => p.lang === pageContext.intl.language),
  ]);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [isLoading, setLoading] = useState(false);

  const loadMoreItems = async (reset?: boolean) => {
    setLoading(true);
    const tag = getQueryValue(window.location.search, 'tag');

    const { entries, hasMore } = await fetchMusicVideos({
      lang: pageContext.intl.language,
      count: 10,
      skip: reset ? 0 : items.length,
      tags: tag ? tag.split(',').map((s) => s.trim()) : [],
    });
    const newItems = entries.map((video) => ({
      path: `/music/${video.slug}-${video.lang}`,
      ...video,
    }));
    setItems(reset ? [...newItems] : [...items, ...newItems]);
    setShowLoadMore(hasMore);
    setLoading(false);
  };

  const masonryBreakPoints = {
    default: 3,
    768: 1,
  };
  const pageDescription = `Trending Music Videos Published by Beat Panda`;
  const onBodyScroll = () => {
    const scrollTop =
      (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;

    const scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;

    if (scrollTop + window.innerHeight >= scrollHeight - 300 && !isLoading && showLoadMore) {
      loadMoreItems(false);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const tag = getQueryValue(window.location.search, 'tag');
      if (tag) {
        setItems([]);
        loadMoreItems(true);
      }
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', onBodyScroll);
      return () => window.removeEventListener('scroll', onBodyScroll);
    }
  }, [isLoading, showLoadMore]);

  return (
    <Layout lang={pageContext.intl.language} basePath='/music'>
      <SeoMetaTags
        lang={pageContext.intl.language}
        title={`${t('common.header.music')} | Beat Panda`}
        description={pageDescription}
        canonicalUrl={buildCanonicalUrl('/music', pageContext.intl.language)}
      />
      <MultiLangTags path={'/music'} basePath={'/music'} />
      <OpenGraphTags
        url={buildCanonicalUrl('/music', pageContext.intl.language)}
        title={t('common.header.music')}
        description={pageDescription}
        image={siteCover}
        locale={pageContext.intl.language}
      />
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <MusicTagSelection
              lang={pageContext.intl.language}
              allTags={pageContext.allTags
                .filter((tag) => tag.lang === pageContext.intl.language)
                .map((tag) => tag.tag)}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <Masonry
              breakpointCols={masonryBreakPoints}
              className={styles.musicPostMasonry}
              columnClassName={styles.musicPostMasonryColumn}
            >
              {items.map((post) => (
                <MusicPostItem
                  key={post.id}
                  id={post.id}
                  title={post.title}
                  lang={pageContext.intl.language}
                  path={post.path}
                  thumbnail={post.thumbnail}
                  summary={post.summary}
                  createdAt={post.createdAt}
                  updatedAt={post.updatedAt}
                />
              ))}
            </Masonry>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default MusicPage;
