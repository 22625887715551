export const getQueryValue = (search: string, key: string): string => {
  if (window.URLSearchParams) {
    const s = new window.URLSearchParams(search);
    return s.get(key);
  } else {
    const searchStr = search[0] === '?' ? search.slice(1) : search;
    const list: string[][] = searchStr.split('&').map((s) => s.split('='));
    for (const tuple in list) {
      if (tuple[0] === key) {
        return tuple[1];
      }
    }
    return null;
  }
};
