import { Locale } from './locale';

export interface IReviewEntry {
  id: string;
  lang: Locale;
  category: ReviewCategory;
  title: string;
  summary: string;
  content?: any;
  author: { id: string; name: string; bio: string };
  cover: string;
  coverWidth: number;
  coverHeight: number;
  visible: boolean;
  tags: string[];
  slug: string;
  path?: string;
  basePath?: string;
  createdAt: string;
  updatedAt: string;
  featured: boolean;
}

export enum ReviewCategory {
  All = 'all',
  Gaming = 'gaming',
  Headphone = 'headphone',
  Tech = 'tech',
  Lifestyle = 'lifestyle',
}
