import React, { FunctionComponent, memo, useEffect, useState } from 'react';
import { Locale } from '../../models/locale';
import { useTranslation } from '../../utils/i18n';
import { getQueryValue } from '../../utils/parser';
import styles from './music-tag-selection.module.scss';

interface IProps {
  lang: Locale;
  allTags: string[];
}

const MusicTagSelection: FunctionComponent<IProps> = memo(({ allTags, lang }) => {
  const { t } = useTranslation();
  const [tags, setTags] = useState<Array<{ name: string; selected: boolean }>>(
    allTags.map((tag) => ({ name: tag, selected: false })),
  );

  const onTagClick = ({ target }) => {
    const tag = getQueryValue(window.location.search, 'tag');
    const tagsInSearch = tag ? tag.split(',').map((tt) => tt.trim()) : [];
    const idx = tagsInSearch.indexOf(target.name);
    let newTag;
    // tslint:disable-next-line:prefer-conditional-expression
    if (idx >= 0) {
      tagsInSearch.splice(idx, 1);
      newTag = tagsInSearch.join(',');
    } else {
      newTag = tag ? `${tag},${target.name}` : target.name;
    }
    window.location.href =
      newTag.length > 0
        ? `${window.location.origin}/${lang}/music?tag=${newTag}`
        : `${window.location.origin}/${lang}/music`;
  };

  const onClearTags = () => {
    window.location.href = `${window.location.origin}/${lang}/music`;
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const tag = getQueryValue(window.location.search, 'tag');
      const selectedTags = tag ? tag.split(',').map((tt) => tt.trim()) : [];
      setTags(
        allTags.map((tt) => {
          const idx = selectedTags.indexOf(tt);
          return { name: tt, selected: idx >= 0 };
        }),
      );
    }
  }, []);

  return (
    <div className={styles.musicTagSelection}>
      <span className='mr-4'>{t('music.tags')}: </span>
      {tags.map((tag) => (
        <div key={tag.name} className={styles.tag}>
          <button
            name={tag.name}
            onClick={onTagClick}
            className={tag.selected ? styles.selected : ''}
          >
            {tag.name}
          </button>
        </div>
      ))}
      <div className={styles.clearButton}>
        <a href='#' onClick={onClearTags}>
          {t('music.clear_tag')}
        </a>
      </div>
    </div>
  );
});

export default MusicTagSelection;
