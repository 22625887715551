import React, { FunctionComponent, memo } from 'react';
import { Locale } from '../../models/locale';
import { Link } from '../../utils/i18n';
import styles from './music-post-item.module.scss';

interface IProps {
  id: string;
  title: string;
  lang: Locale;
  thumbnail: string;
  path: string;
  summary: string;
  createdAt: string;
  updatedAt: string;
}

const MusicPostItem: FunctionComponent<IProps> = memo(
  ({ id, lang, title, thumbnail, path, summary, updatedAt }) => {
    return (
      <article className={styles.musicPostItem}>
        <div className='entry-header'>
          <div className={styles.postIntro}>
            <Link to={`${path}/`}>
              <img src={thumbnail} alt={title} />
              <div className={styles.playMask}>
                <i className='fas fa-video' />
              </div>
            </Link>
          </div>
          <div className={styles.postDetail}>
            <h2 className={styles.entryTitle}>
              <Link to={`${path}/`} rel='bookmark'>
                {title}
              </Link>
            </h2>
            <div className={styles.postInfo}>
              <p>{summary}</p>
            </div>
          </div>
        </div>
      </article>
    );
  },
);

export default MusicPostItem;
