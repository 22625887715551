import { createClient } from 'contentful';
import { Locale } from '../models/locale';
import { IMusicVideoEntry } from '../models/music-video-entry';
import { IReviewEntry, ReviewCategory } from '../models/review-entry';

const contentfulClient = createClient({
  space: '105rjcaumfu4',
  accessToken: 'A5mdQL1Msc5LcmDbaqjOqtDhIDIJkkcTifB_1aXysd0',
});

const parseImageFileUrl = (fields) => {
  const src = fields?.file?.url;
  return src ? `${src}?w=960&fl=progressive&q=80` : null;
};

/**
 * prevent unexpected 301 redirect after deployed on netlify
 * @param lang
 */
const convertLangCode = (lang: Locale): string => {
  switch (lang) {
    case Locale.EN:
      return lang;
    case Locale.TW:
      return 'zh-Hant';
  }
};

export const getReviewCounts = async (query: { lang?: Locale }): Promise<number> => {
  const params = { content_type: 'blogPost', limit: 1 };
  if (query.lang) {
    // @ts-ignore
    params.locale = convertLangCode(query.lang);
  }
  const { total, errors } = await contentfulClient.getEntries(params);
  if (errors && errors.length > 0) {
    throw errors;
  }

  return total;
};

export const fetchReviews = async (query: {
  lang: Locale;
  count?: number;
  skip?: number;
  category?: ReviewCategory;
  search?: string;
}): Promise<{ entries: IReviewEntry[]; hasMore: boolean }> => {
  try {
    const params = {
      content_type: 'blogPost',
      skip: query.skip ?? 0,
      limit: query.count ?? 10,
      order: '-sys.updatedAt',
      locale: convertLangCode(query.lang),
      include: 2,
    };
    if (query.category && query.category !== ReviewCategory.All) {
      params['fields.category'] = query.category;
    }
    if (query.search && query.search.length > 0) {
      params['fields.title[match]'] = query.search;
    }
    const { items, errors, total } = await contentfulClient.getEntries<any>(params);
    if (errors && errors.length > 0) {
      throw errors;
    }
    const entries = items.map<IReviewEntry>((item) => ({
      id: item.sys.id,
      slug: item.fields.slug,
      lang: item.sys.locale.toLowerCase() as Locale,
      author: {
        id: item.fields.author.id,
        name: item.fields.author.name,
        bio: item.fields.author.bio,
      },
      title: item.fields.title,
      summary: item.fields.summary,
      content: item.fields.description,
      cover: parseImageFileUrl(item.fields.cover?.fields),
      coverWidth: 0,
      coverHeight: 0,
      visible: item.fields.visible,
      tags: item.fields.tags,
      createdAt: item.sys.createdAt,
      updatedAt: item.sys.updatedAt,
      featured: item.fields.featured,
    }));
    return { entries, hasMore: query.skip + items.length < total };
  } catch (ex) {
    console.log(ex);
    return { entries: [], hasMore: false };
  }
};

export const getMusicVideoCount = async (query: { lang?: Locale }): Promise<number> => {
  const params = { content_type: 'musicVideo', limit: 1 };
  if (query.lang) {
    // @ts-ignore
    params.locale = convertLangCode(query.lang);
  }
  const { total, errors } = await contentfulClient.getEntries(params);
  if (errors && errors.length > 0) {
    throw errors;
  }

  return total;
};

export const fetchMusicVideos = async (query: {
  lang: Locale;
  count?: number;
  skip?: number;
  tags?: string[];
  search?: string;
}): Promise<{ entries: IMusicVideoEntry[]; hasMore: boolean }> => {
  try {
    const params = {
      content_type: 'musicVideo',
      skip: query.skip ?? 0,
      limit: query.count ?? 10,
      order: '-sys.updatedAt',
      locale: convertLangCode(query.lang),
      include: 2,
    };
    if (query.tags && query.tags.length > 0) {
      params['fields.tags[all]'] = query.tags.join(',');
    }
    if (query.search && query.search.length > 0) {
      params['fields.title[match]'] = query.search.trim();
      params['fields.summary[match]'] = query.search.trim();
    }

    const { items, errors, total } = await contentfulClient.getEntries<any>(params);

    if (errors && errors.length > 0) {
      throw errors;
    }

    const entries = items.map<IMusicVideoEntry>((item) => ({
      id: item.sys.id,
      slug: item.fields.slug,
      lang: item.sys.locale.toLowerCase() as Locale,
      title: item.fields.title,
      summary: item.fields.summary,
      description: item.fields.description,
      youtubeVideoUrl: item.fields.youtubeVideoUrl,
      thumbnail: parseImageFileUrl(item.fields.thumbnail?.fields),
      cover: parseImageFileUrl(item.fields.cover?.fields),
      visible: item.fields.visible,
      tags: item.fields.tags,
      playlist: item.fields.playlist,
      createdAt: item.sys.createdAt,
      updatedAt: item.sys.updatedAt,
    }));
    return { entries, hasMore: query.skip + items.length < total };
  } catch (ex) {
    console.log(ex);
    return { entries: [], hasMore: false };
  }
};
